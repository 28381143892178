<template>
  <div :class="seoPagesClass">
    <wl-header
      v-if="!isTrackingPage"
      id="header"
      :logo-title="header.logo_title"
      :menu="
        showFullToggleMenu() ? [] : header.menu.slice(0, menuLinksDesktopCount)
      "
      :path="path"
      :is-fixed="isFixed"
      :hide-menu="isLanding"
      :show-aux-menu="!showFullToggleMenu() && !isLanding"
      :class="{
        'wl-header--mobile-search': mobileSearchActive && isMobile,
        'wl-header--home': isHome,
        'wl-header--cornerstone': isCornerStone,
        'wl-header--landing': isLanding
      }"
    >
      <template #header-logo>
        <a class="wl-header__logo" href="/" :title="header.logo_title">
          <span class="wl-header__logo-text">UnM</span
          ><span class="wl-header__logo-image">a</span
          ><span class="wl-header__logo-text">sk</span
          ><span class="wl-header__logo-reg-trademark">&reg;</span>
        </a>
      </template>
      <template #header-bar v-if="!isLanding">
        <button
          type="button"
          id="searchToggle"
          class="um-search-toggle"
          aria-controls="searchBar"
          aria-expanded="false"
          aria-label="Toggle Search"
          v-touch:tap="mobileSearchOpen"
        >
          <span class="um-search-toggle__text">{{ mobileSearchText }}</span>
          <img
            :src="header.mobile_search_icon"
            alt="Search Icon"
            loading="lazy"
            width="15px"
            height="15px"
          />
        </button>
        <button
          type="button"
          id="searchClose"
          class="um-search-cancel"
          v-touch:tap="mobileSearchClose"
        >
          Cancel
        </button>
        <tz-search
          v-if="showDesktopSearchBar()"
          :in-header="true"
          :prefill-fields="prefillFields"
          :fallback-fields="fallbackFields"
          :people-link="peopleURL()"
          :no-query-string="true"
          @tz-search-override-redirect="navigateToSeo"
        />
        <ul id="toggle-menu" class="wl-header__toggle-menu">
          <li
            class="wl-header__toggle-menu-item"
            :key="link.label"
            v-for="link in showFullToggleMenu()
              ? header.menu
              : header.menu.slice(menuLinksDesktopCount)"
          >
            <a
              class="wl-header__toggle-menu-link"
              :class="{
                'wl-header__toggle-menu-link--active': path === link.url
              }"
              :href="link.url"
              role="button"
              aria-haspopup="true"
              aria-expanded="false"
              v-text="link.label"
            />
          </li>
        </ul>
      </template>
    </wl-header>
    <div class="inside-content" ref="insideContent">
      <nuxt
        ref="page"
        :prefill-fields="prefillFields"
        :fallback-fields="fallbackFields"
      />
      <div class="footer-states">
        <div class="footer-states__container">
          <h2 class="footer-states__title">Browse by States:</h2>
          <ul class="footer-states__list">
            <li
              class="footer-states__item"
              v-for="(stateName, stateAbbr) in getStates()"
              :key="stateAbbr"
            >
              <a
                class="footer-states__link"
                :href="`/address/states/${stateAbbr}/`"
                v-text="stateAbbr"
              />
            </li>
          </ul>
        </div>
      </div>
      <tz-footer
        v-if="!isTrackingPage"
        :class="{
          'tz-footer--landing': isLanding,
          'tz-footer--default': !isLanding
        }"
        :header-fixed="isFixed"
        :footer="
          isLanding ? { ...footer, disclaimer: landingDisclaimer } : footer
        "
        :alpha-base-link="'/people/'"
        :alpha-link-pad="true"
        :has-trailing-slash="true"
        :display-sections="
          isLanding
            ? ['globalLinks', 'disclaimer']
            : [
                'alphaSelect',
                'contactInfo',
                'columnPrimary',
                'columnSecondary',
                'columnTertiary',
                'columnQuarternary',
                'disclaimer'
              ]
        "
        :page="{
          path: $route.path,
          name: $route.name,
          params: $route.params
        }"
        :show-scroll-to-top="true"
        v-on:scroll-to-top="scrollToTop"
      />
    </div>
  </div>
</template>
<script>
// component
import TzHeader from '@trazi/tz-header/src/tz-header.vue';
import TzSearch from '@trazi/tz-search/src/tz-search.vue';
import TzFooter from '@trazi/tz-footer/src/tz-footer.vue';
import WlHeader from '@/components/shared/wl-header.vue';
import helpers from '@/assets/js/shared/misc/helpers';
import { unregisterAndClearCaches } from '@/assets/js/shared/service-worker';
import setTitleCase from '@/assets/js/shared/helpers/setTitleCase';
import getStates from '@/assets/js/shared/helpers/getStatesList';
import formatCity from '@/assets/js/shared/helpers/formatCity';
import userLocation from '@/mixins/shared/userLocation';
import getMobileQuery from '@/assets/js/shared/helpers/getMobileQuery';
import mediaQueryListener from '@/assets/js/shared/helpers/mediaQueryListener';
const mobileMediaQuery = getMobileQuery({ width: 992 });
const footerCMS = require(`@/assets/cms/components/footer.json`);
let insertionIndex = 1;
// TODO: use getLastXDays method from js submodules once its been created
for (let i = 1; i < 13; i++) {
  const today = new Date();
  const priorDate = new Date(
    new Date().setDate(today.getDate() - i)
  ).toLocaleDateString(undefined, {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric'
  }); // returns MM/DD/YYYY
  const mm = priorDate.split('/')[0];
  const m = priorDate.split('/')[0].replace(/^0/, '');
  const dd = priorDate.split('/')[1];
  const d = priorDate.split('/')[1].replace(/^0/, '');
  const yyy = priorDate.split('/')[2];

  const dateEntry = {
    label: `${mm}/${dd}`,
    url: `${footerCMS.quarternary_list[0].url}${yyy}-${m}-${d}/`
  };

  footerCMS.quarternary_list.splice(insertionIndex, 0, dateEntry);
  insertionIndex++;
}

export default {
  name: 'unmask',
  mixins: [userLocation],
  components: {
    TzHeader,
    TzSearch,
    TzFooter,
    WlHeader
  },
  props: {
    footer: {
      type: Object,
      default: () => footerCMS
    },
    header: {
      type: Object,
      default: () => require(`@/assets/cms/components/header.json`)
    }
  },
  data() {
    return {
      getStates,
      logoSize: { height: '29', width: '159' },
      logoSmallSize: { height: '25', width: '26' },
      menuLinksDesktopCount: 3,
      isMobile: false,
      isErrorPage: false,
      mobileSearchActive: false,
      mobileSearchText: '',
      banner: null,
      bannerSearch: null,
      searchNotVisibleOffset: 0,
      isFixed: false,
      prefillFields: {
        firstName: '',
        lastName: '',
        city: '',
        state: this.$store.getters['location/state']
      },
      fallbackFields: [{ aid: 25 }, {}],
      params: {},
      path: null,
      userCurrent: null,
      siteURL: ''
    };
  },
  computed: {
    seoPagesClass() {
      if (!this.isSeoSearch) {
        return;
      }

      return 'um-seo';
    },
    landingDisclaimer() {
      return this.footer.landing_disclaimer;
    },
    isHome() {
      return this.$route.path === '/';
    },
    isTrackingPage() {
      return this.$route.name === 'tracking';
    },
    isLanding() {
      return this.$route.name && this.$route.name.includes('flow');
    },
    isSeoSearch() {
      return this.$route.name && this.$route.name.startsWith('results');
    },
    showDesktopSearchBarAtAllTimes() {
      if (!this.$route.name || !this.$route.path) {
        return true;
      }
      return (
        this.$route.path.includes('privacy-policy') ||
        this.$route.path.includes('terms-conditions') ||
        this.$route.path.includes('opt-out') ||
        this.$route.path.includes('login') ||
        this.$route.path.includes('most-popular') ||
        this.$route.path.includes('trending-name') ||
        this.isSeoSearch ||
        this.$route.name.includes('index.letter')
      );
    },
    showDesktopSearchBarWhenFixedOnly() {
      const routeNamesToInclude = ['cornerstone', 'home', 'address', 'contact'];
      return (
        this.$route.name &&
        routeNamesToInclude.some(name => this.$route.name.includes(name))
      );
    },
    isSeoPersonSearch() {
      return (
        this.isSeoSearch &&
        this.$route.name &&
        !this.$route.name.endsWith('phones') &&
        !this.$route.name.endsWith('address')
      );
    },
    isCornerStone() {
      return /cornerstone/g.test(this.$route.name);
    },
    isEmptyQuery() {
      return Object.keys(this.$route.query).length === 0;
    },
    searchOffsetElement() {
      // TODO: Only return until elements are added to page
      return {
        offsetTop: 0,
        offsetHeight: 20
      };

      const bannerSlots = this.$refs.insideContent.querySelector(
        '.tz-banner__slots'
      );

      // If there is a banner search, show and hide header search when scrolled past ex. Home Page
      if (bannerSlots && bannerSlots.offsetHeight > 0) {
        return bannerSlots;
      }

      const bannerDesc = this.$refs.insideContent.querySelector(
        '.tz-banner__desc'
      );

      // If there is a banner with a header, show and hide search when scrolled past ex. Privacy Policy
      if (bannerDesc && bannerDesc.offsetHeight > 0) {
        return bannerDesc;
      }

      // Otherwise default to breadcrumbs ex. City Results Page
      return this.$refs.insideContent.querySelector('.tz-breadcrumbs');
    }
  },
  async created() {
    const { name, params, query } = this.$route;
    const state = !params.stateAbbr
      ? this.$store.getters['location/state']
      : params.stateAbbr;
    this.path = this.$route.path;
    this.isErrorPage = this.$route.name === null;
    if (this.isSeoSearch || name === 'results.profile') {
      this.params.firstName = helpers.formatFirstName(params.firstName);

      this.params.lastName = helpers.formatName(
        helpers.formatUnderScoreToSpace(params.lastName)
      );

      this.params.state = state;

      if (params.city) {
        this.params.city = helpers.formatName(
          params.city.replace(helpers.underscoreRegEx, ' ')
        );
      }

      this.updatePrefillFields(this.params);
    }
    this.updatePrefillFields(query);
    this.setMobileText();

    if (this.isSeoSearch) {
      // locking seo search pages into having the search bar in view at all times.
      this.isFixed = true;
    }
  },
  async mounted() {
    try {
      // TODO: Remove after next release we want to temporarily clear servive workers for visitors to fix sidebar glitch
      await unregisterAndClearCaches();
    } catch (err) {
      // ignore if error is thrown
      this.$bugsnag.notify(err);
    }

    const isUserLoggedIn = this.$authHelper.isUserLoggedInSync();

    if (isUserLoggedIn) {
      const userCurrent = await this.$authHelper.getCurrentUser();
      if (userCurrent === 'Access Denied') {
        await this.$authHelper.logoutUser('/login');
      }
    }

    const loggedInRedirect = this.getLoggedInRedirectInfo();
    if (isUserLoggedIn && loggedInRedirect.status) {
      await this.$router.push({ name: loggedInRedirect.routeName });
    }

    this.siteURL = window.location.origin;

    this.setScrollEvent();

    // Remove any registered service workers.
    unregisterAndClearCaches();

    window.onpageshow = function(event) {
      /* Safari Back Button Persistent Cache Fix */
      if (event.persisted) {
        window.location.reload();
      }
    };
    mediaQueryListener({
      selector: mobileMediaQuery,
      handleMethod: this.setIsMobile
    });
    this.isMobile = mobileMediaQuery.matches;
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
    if (mobileMediaQuery && mobileMediaQuery.removeEventListener) {
      mobileMediaQuery.removeEventListener('change', this.setIsMobile);
    }
  },
  methods: {
    showFullToggleMenu() {
      return this.isMobile || this.showDesktopSearchBar();
    },
    showDesktopSearchBar() {
      return (
        this.showDesktopSearchBarAtAllTimes ||
        (this.isFixed && this.showDesktopSearchBarWhenFixedOnly)
      );
    },
    setIsMobile(e) {
      this.isMobile = e.matches;
    },
    /**
     * Redirects user
     * @param {object} router
     * @param {string} routeName
     */
    redirect(router, routeName) {
      const { href } = router.resolve({
        name: routeName
      });
      location.href = href;
    },
    /**
     * returns path for people search
     * @returns {String}
     */
    peopleURL() {
      return `https://unmask.com/searching/`;
    },
    /**
     * Handle logout
     * @returns {Promise<void>}
     */
    handleLogOut() {
      this.$authHelper.logoutUser('/login');
    },
    mobileSearchOpen() {
      this.mobileSearchActive = true;
    },
    mobileSearchClose() {
      this.mobileSearchActive = false;
    },
    setMobileText() {
      this.mobileSearchText = this.header.mobile_search_label;

      if (this.isEmptyQuery && this.isSeoPersonSearch) {
        this.mobileSearchText = `${this.params.firstName} ${this.params.lastName}`;

        if (this.params.city) {
          this.mobileSearchText += ` in ${this.params.city}, ${this.params.state}`;
        } else if (this.params.state) {
          this.mobileSearchText += ` in ${this.params.state}`;
        }
      }

      if (this.$route.query.firstName || this.$route.query.lastName) {
        this.mobileSearchText = `Find ${this.$route.query.firstName ||
          ''} ${this.$route.query.lastName || ''}`;
      }
    },
    scrollToTop() {
      scroll({
        top: 0,
        behavior: 'smooth'
      });
    },
    setScrollEvent() {
      // Don't show banner in header on scroll if on a landing page or if there is not an element to offset from
      if (this.isLanding || !this.searchOffsetElement) return;
      window.addEventListener('scroll', this.handleScroll, { passive: true });
    },
    handleScroll() {
      if (this.isSeoSearch) {
        return;
      }
      this.searchNotVisibleOffset =
        this.searchOffsetElement.offsetTop +
        this.searchOffsetElement.offsetHeight;
      this.isFixed = window.scrollY >= this.searchNotVisibleOffset;
    },
    updatePrefillFields(updatedFields) {
      this.prefillFields = Object.assign({}, this.prefillFields, updatedFields);
    },
    getLoggedInRedirectInfo() {
      if (this.$route.name === 'contact') {
        return {
          status: true,
          routeName: 'dashboard-contact'
        };
      } else if (this.$route.name === 'privacy-policy') {
        return {
          status: true,
          routeName: 'dashboard-privacy-policy'
        };
      } else if (this.$route.name === 'terms-conditions') {
        return {
          status: true,
          routeName: 'dashboard-terms-conditions'
        };
      } else if (this.$route.name === 'optout') {
        return {
          status: true,
          routeName: 'dashboard-optout'
        };
      } else if (this.$route.name === 'optout-request') {
        return {
          status: true,
          routeName: 'dashboard-optout-request'
        };
      } else if (this.$route.name === 'optout-confirmation') {
        return {
          status: true,
          routeName: 'dashboard-optout-confirmation'
        };
      } else if (/dashboard|privacy|terms|contact|opt/.test(this.$route.name)) {
        return {
          status: false,
          routeName: null
        };
      } else {
        return {
          status: true,
          routeName: 'dashboard-reports'
        };
      }
    },
    /**
     * Takes user to phone/people SEO page
     * @param {object} event
     */
    navigateToSeo(event) {
      const {
        firstName,
        lastName,
        city,
        state,
        phoneNumber,
        street,
        searchType
      } = event;
      const { query } = this.$route;
      let searchLink = '';

      switch (searchType) {
        case 'address':
          const streetParam = street.replace(/ /g, '-').replace(/#/g, '%7C');
          const cityParam = formatCity({ city }).replace(/ /g, '_');
          searchLink = this.$router.resolve({
            name: 'results.address',
            query: { ...query },
            params: {
              street: streetParam,
              city: cityParam,
              state
            }
          }).href;
          break;
        case 'phone':
          const rawPhoneNumber = phoneNumber.replace(/\D+/g, '');
          const phoneParam = dashedPhoneNumber({ phoneNumber: rawPhoneNumber });
          searchLink = this.$router.resolve({
            name: 'seo.phone',
            query: { ...query },
            params: {
              phone: phoneParam
            }
          }).href;
          break;
        case 'people':
          const searchQuery = {
            firstName: setTitleCase({ text: firstName }),
            lastName: setTitleCase({ text: lastName }),
            city: formatCity({ city }),
            state
          };
          searchLink = this.$router.resolve({
            name: 'flow.searching',
            params: { landingID: this.landingID },
            query: {
              ...query,
              time: Date.now(),
              ...searchQuery
            }
          }).href;
          break;
      }

      window.location = searchLink;
    }
  },
  head() {
    const head = {
      meta: [],
      script: []
    };
    const hasNoCrawling = /open-report|flow|results.profile/.test(
      this.$route.name
    );

    // If route has no crawling add noindex nofollow noarchive and return early
    if (hasNoCrawling) {
      head.meta.push({
        name: 'robots',
        hid: 'robots',
        content: 'noindex, nofollow, noarchive'
      });

      return head;
    }

    const isSeoPage = /results/.test(this.$route.name);
    const website = `https://unmask.com`;
    let path =
      this.$route.path.slice(-1) === '/'
        ? this.$route.path
        : this.$route.path + '/';

    // SEO pages have their own canonical link logic that accounts for title casing
    if (!isSeoPage && this.$route.name) {
      let canonicalPath = path.toLowerCase();
      switch (this.$route.name) {
        // Capitalizes routes that need to be capitalized
        case 'cornerstone.public-records':
        case 'cornerstone.background-checks':
        case 'cornerstone.criminal-records':
          const capitalizedPath = setTitleCase({ text: path });
          canonicalPath = `${website}${capitalizedPath}`;
          break;
        // Upper cases the params
        case 'top-full-names.state':
        case 'top-last-names.letter':
        case 'top-last-names.state':
        case 'address.cities':
          const pattern = /\/([^/]+)\/$/; // gets the last string before the ending slash
          const match = path.match(pattern);
          const lastPiece = match[1];
          const updatedLastPiece = lastPiece.toUpperCase();
          const updatedPath =
            path.toLowerCase().slice(0, match.index + 1) +
            updatedLastPiece +
            '/';
          canonicalPath = `${website}${updatedPath}`;
          break;
        case 'address.streets':
          const pageRegex = /\/\d+\/$/;
          const hasPageNumber = this.$route.params.page;

          if (!hasPageNumber) {
            canonicalPath = `${website}${path}`;
          } else {
            const streetPath = path.replace(pageRegex, '/');
            canonicalPath = `${website}${streetPath}`;
          }

          break;
        default:
          canonicalPath = `${website}${path}`;
      }
      head.link = [
        {
          rel: 'canonical',
          href: canonicalPath
        }
      ];
    }
    // Setup nav links for nav schema
    const navLinks = this.header.menu
      .concat(
        [...'ABCDEFGHIJKLMNOPQRSTUVWXYZ'].map(letter => ({
          url: `/people/${letter}000000/`,
          name: letter
        }))
      )
      .concat(this.footer.secondary_list)
      .concat(this.footer.global_list);
    const navSchema = [];
    // Loop through links to build nav schema
    for (let i = 0; i < navLinks.length; i++) {
      const link = navLinks[i].url.startsWith('/')
        ? website + navLinks[i].url
        : navLinks[i].url;
      navSchema.push({
        '@type': 'SiteNavigationElement',
        '@id': link,
        url: link,
        name: navLinks[i].label
      });
    }
    // Push nav schema to head
    head.script.push({
      type: 'application/ld+json',
      json: { '@context': 'https://schema.org/', '@graph': navSchema }
    });

    // Push org schema to head
    head.script.push({
      type: 'application/ld+json',
      json: {
        '@context': 'http://schema.org',
        '@type': 'Organization',
        name: 'UnMask',
        url: website,
        alternateName: 'UnMask.com',
        logo: `${website}/images/logo-solid.png`,
        contactPoint: [
          {
            '@type': 'ContactPoint',
            email: 'contact@unmask.com',
            url: `${website}/contact/`,
            contactType: 'Customer Service',
            areaServed: 'US'
          }
        ]
      }
    });

    return head;
  }
};
</script>
